import './App.css';
import { connect } from 'react-redux';
import MainPage from './components/MainPage';
import { BrowserRouter, Route, Link , Switch} from "react-router-dom";
import TopNavBar from './components/TopNavBar';
import Footer from './components/Footer';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faTimes, faTimesCircle, faBars, faPlus, faPlusCircle, faArrowLeft, faEraser, faCopy } from '@fortawesome/free-solid-svg-icons'
import AboutSection from './components/AboutSection';

library.add(fab, faCheckSquare, faTimes, faTimesCircle, faBars, faPlus, faPlusCircle, faArrowLeft, faEraser, faCopy);

function App() {

  return (
    <div>
      <div className="wrapper AppWrapper">
        <BrowserRouter>
            <TopNavBar/>
              <Switch>
              <Route path="/MultiClipboard">
                <MainPage/>
              </Route>
              <Route path="/">
                <MainPage/>
              </Route>
              </Switch>
              <AboutSection/>
            
              <Footer/>
        </BrowserRouter>
      </div>
    </div>
    /*
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
    */
  );
}

export default App
