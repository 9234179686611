import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import localforage from 'localforage';
import { PrefixConstants } from '../constants/PrefixConstants';
import _ from 'lodash';

function AutoSaveComponent ({ snippets, onLoadSnippets }) {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    //console.log("UE-01");
    let browserStorageSnippets = localforage.getItem(PrefixConstants.STORAGE_KEY_SNIPPETS);
    
    browserStorageSnippets.then((fetchedSnippets) => {
      //console.log("fetchedSnippets");
      //console.log(fetchedSnippets);
      localforage.setItem(PrefixConstants.STORAGE_KEY_SNIPPETS, fetchedSnippets).then((setSnippets) => {
        //console.log("set Loaded true");
        //console.log(setSnippets);
        let a = _.cloneDeep(setSnippets);
        onLoadSnippets(a);
        setLoaded(true);
      });
    });

  },[]);

  //console.log("AutoSaveComponent");

  //console.log(loaded);
  if(loaded === true){
    //console.log(snippets);
    localforage.setItem(PrefixConstants.STORAGE_KEY_SNIPPETS, snippets);
  }

  return (
  <div>
    
  </div>
  )
}

export default AutoSaveComponent
