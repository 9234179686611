import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from './Tooltip';

function SnippetItem (props) {

  const [tooltipKey, setTooltipKey] = useState(0);

  function stringIsEmpty(value){
    return !value || value == null || value.length === 0;
  }
  
  const copyToClipboard = (copyingValue, isAuto) => {
    if(stringIsEmpty(copyingValue)){
      return;
    }
    /*
    if (!navigator.clipboard){
      var copyTextarea = document.getElementById("clipTextArea");
      copyTextarea.select(); //select the text area
      document.execCommand("copy"); //copy to clipboard ('document.execCommand' is deprecated.)
    } else
    */{
      navigator.clipboard.writeText(copyingValue).then(
          function(){
            setTooltipKey(tooltipKey+1);
            //console.log("copied!"); // success 
            //  setNotificationContent("Copied to clipboard!");
          })
        .catch(
          function() {
            //console.log("failed to copy"); // error
            if(!isAuto){
            //  setNotificationContent("Failed to copy the text, please copy manually");
            }
        });
    }  
  }

  let tabIndexItemCount = 3;

  let copyButtonTabIndex = props.tabIndexOffset + props.tabIndex + props.tabIndexListLength * 0;
  let inputTabIndex = props.tabIndexOffset +  props.tabIndex + props.tabIndexListLength * 1;
  let deleteButtonTabIndex = props.tabIndexOffset +  props.tabIndex + props.tabIndexListLength * 2;

  return (
    <div key={props.id} className="snippet-item">
      <div className="snippet-item-wrapper">
        <button className="snippet-item-delete-button" onClick={(e) => {props.deleteSnippetCallback(props.id);}} tabIndex={deleteButtonTabIndex}>
          <FontAwesomeIcon icon="times" />
        </button>
        <input className="snippet-item-input" defaultValue={props.text} onChange={(e) => {props.updateSnippetCallback(props.id, e.target.value);}} tabIndex={inputTabIndex}/>
        <button className="snippet-item-copy-button" onClick={(e) => {copyToClipboard(props.text, false)}} tabIndex={copyButtonTabIndex}>
          <FontAwesomeIcon icon="copy" />
        </button>
        <div className="toolip-div">
          {
            tooltipKey > 0 && 
            <Tooltip text="Copied" arrowDirection="LEFT" key={props.id + ":tooltip-" + tooltipKey}/>
          }
        </div>
        <span className="snippet-item-drag-indicator">
          <FontAwesomeIcon icon="bars" />
        </span>
      </div>
      
    </div>
  )
  
}

export default SnippetItem
