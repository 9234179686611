

export const addSnippet = text => ({
  type: 'ADD_SNIPPET',
  text
})

export const loadSnippets = snippets => ({
  type: 'LOAD_SNIPPETS',
  snippets
})
export const clearSnippets = () => ({
  type: 'CLEAR_SNIPPETS'
})
export const deleteSnippet = id => ({
  type: 'DELETE_SNIPPET',
  id
})
export const reorderSnippets = (sourceIndex, destinationIndex) => ({
  type: 'REORDER_SNIPPETS',
  sourceIndex,
  destinationIndex
})
export const updateSnippet = (id, text) => ({
  type: 'UPDATE_SNIPPET',
  text,
  id
})
