import '.././App.css';

function AboutSection () {
  return (
    <div className="about-section">
      <div className="about-content-div">
        <div className="title">        
          <span>About</span>
        </div>
        
        <div className="content">
          <span>
            Multi Clipboard is an online web tool helping users store text in the browser and allowing copying to clipboard faster, by a one-click action. It is a serverless application, your text will not be sent to any server, it will only be saved in your browser.
          </span>
        <div className="content">        
          <span>
            Generally, the clipboard is part of the operating system, and its purpose is to store short-term data and transfer that data between files or applications using copy and paste operations. In an environment with a graphical user interface (GUI), a clipboard is a commonly used function. It is usually implemented as anonymous temporary data objects that can be accessed by many programs.
          </span>
        </div>
        <div className="content">        
          <span>
            Various operating systems implement clipboards differently, and sometimes even different editions of the same OS. Various programs or user settings may also change how clipboards are implemented. Each "cut" or "copy" operation overwrites previous clipboard contents in most environments. A pasting action will retain the content in the clipboard for later pasting.
        </span>
        </div>
        <div className="content">        
          <span>
            Some of the OS stores copied items for the clipboard, in order to let users paste items easier and faster. However, the clipboard might be cleared while the device is rebooted. Multi Clipboard is one of the solutions for keeping the clipboard items even if the device is rebooted since it stores the copied item in the browser.
        </span>
        </div>
        <div className="content">        
          <span>
            Initially, clipboard data was stored as plain text without meta-details like font, style, and color. Later versions supported multiple types of data, allowing complex data structures to be saved. At this moment, Multi-clipboard supports plain text only.
          </span>
        </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;