import { connect } from 'react-redux'
import { deleteSnippet, updateSnippet, reorderSnippets } from '../actions'
import SnippetList from '../components/SnippetList'


function mapStateToProps (state) {
  return (
    {
      snippets: state.snippets
    }
  )
}

const mapDispatchToProps = (dispatch) => ({
  onDeleteSnippet: (id) => {dispatch(deleteSnippet(id));},
  onUpdateSnippet: (id, text) => {dispatch(updateSnippet(id, text));},
  onReorderSnippets: (sourceIndex, destinationIndex) => {dispatch(reorderSnippets(sourceIndex, destinationIndex));}
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnippetList)
