import '.././App.css';

function Tooltip (props) {
  return (
    <div className="tooltip">
      <div className="row-wrapper top-row">
        <div className="top-arrow-wrapper">
          {
            (props.arrowDirection == "TOP") && <div className="pointer"></div>
          }
        </div>
      </div>
      
      <div className="row-wrapper mid-row">
        <div className="left-arrow-wrapper">
          {
            (props.arrowDirection == "LEFT") && <div className="pointer"></div>
          }
        </div>
        <div className="content">
          <span>{props.text}</span>
        </div>
        <div className="right-arrow-wrapper">
          {
            (props.arrowDirection == "RIGHT") && <div className="pointer"></div>
          }
        </div>
      </div>
      
      <div className="row-wrapper bottom-row">
        <div className="bottom-arrow-wrapper">
          {
            (props.arrowDirection == "BOTTOM") && <div className="pointer"></div>
          }
        </div>
      </div>
    </div>
  );
}

export default Tooltip;