import { connect } from 'react-redux'
import { loadSnippets } from '../actions'
import AutoSaveComponent from '../components/AutoSaveComponent'


function mapStateToProps (state) {
  return (
    {
      snippets: state.snippets
    }
  )
}

const mapDispatchToProps = (dispatch) => ({
  onLoadSnippets: (snippets) => {dispatch(loadSnippets(snippets));}
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoSaveComponent)
