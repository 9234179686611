import {v4 as uuidV4} from 'uuid';
import _ from 'lodash';

function snippets (state = [], action) {
  //console.log(action);
    switch (action.type) {
      case 'ADD_SNIPPET':
        let newId = uuidV4();
        return [
          ...state,
          {
            id: newId,
            text: action.text,
            status: 0
          }
        ];
      case 'LOAD_SNIPPETS':
        if(action.snippets != null){
          return action.snippets;
        }
        return state;
      case 'CLEAR_SNIPPETS':
        return [];
      case 'DELETE_SNIPPET':
        return state.filter(item => item.id !== action.id);
      case 'REORDER_SNIPPETS':
        /*
        sourceIndex
        destinationIndex
        */
        //console.log(action);
        let clonedArray = _.cloneDeep(state);
        let sourceItem = _.pullAt(clonedArray, [action.sourceIndex]);
        let splitIndex = action.destinationIndex;
        if(action.sourceIndex < action.destinationIndex){
          splitIndex = splitIndex ;
        }
        let slicedArray0 = _.slice(clonedArray, 0, splitIndex);
        let slicedArray1 = _.slice(clonedArray, splitIndex, clonedArray.length);
        //console.log(splitIndex);
        const reorderedArray = _.concat(slicedArray0, sourceItem, slicedArray1);
        //console.log(reorderedArray);
        return reorderedArray;
      case 'UPDATE_SNIPPET':
          const updatedArray = _.cloneDeep(state);
          const index = updatedArray.findIndex(item => item.id === action.id);
          updatedArray[index].text = action.text;
          return updatedArray;
      default:
        return state
    }
  }
  
  export default snippets
  