import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import localforage from 'localforage';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import '.././App.css';
import { addSnippet, clearSnippets } from '../actions';
import { PrefixConstants } from '../constants/PrefixConstants';

function ClearSnippet ({dispatch}) {
  let input;
  
  return (
    <div className="clear-snippet">
      <button type="submit" className="clear-snippet-button" onClick={() => (dispatch(clearSnippets()))}>
          
          <FontAwesomeIcon icon="eraser" className="add-snippet-button-icon"/>
          <span>Clear all snippets</span>
        </button>
    </div>
  );
}

export default connect()(ClearSnippet);
