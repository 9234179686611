import '.././App.css';

function TopNavBar () {
  
  return (
    <div className="top-nav-bar">
      <div className="title-div">
        <span>
          Multi Clipboard
        </span>
      </div>
      
    </div>
  );
}

export default TopNavBar;