import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import localforage from 'localforage';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import '.././App.css';
import { addSnippet } from '../actions';
import { PrefixConstants } from '../constants/PrefixConstants';


function onSubmitForm (e, dispatch, input){
  e.preventDefault()
  if (!input.value.trim()) {
    return
  }
  dispatch(addSnippet(input.value))
  input.value = ''
}

function AddSnippet ({dispatch}) {
  let input;
  
  return (
    <div className="add-snippet">
      <form className="add-snippet-form" onSubmit={(e) => onSubmitForm(e, dispatch, input)}>
        <div className="add-snippet-form-content-wrapper">
          <input className="add-snippet-input" ref={node => input = node} placeholder="Add your text here..."/>
          <button type="submit" className="add-snippet-button">
            <FontAwesomeIcon icon="plus" className="add-snippet-button-icon"/>
          </button>
        </div>
      </form>
    </div>
  );
}

export default connect()(AddSnippet);
