import { connect } from 'react-redux';
import '.././App.css';
import { addSnippet } from '../actions';
import AutoSaveContainer from '../containers/AutoSaveContainer';
import VisibleSnippetList from '../containers/VisibleSnippetList';
import AboutSection from './AboutSection';
import AddSnippet from './AddSnippet';
import ClearSnippet from './ClearSnippet';

function MainPage ({dispatch}) {
  return (
    <div className="component-page main-page">
      <div className="component-page-wrapper">
        <AutoSaveContainer/>
        <div className="component-div clear-snippet-div">
          <ClearSnippet />
        </div>
        <div className="component-div add-snippet-div">
          <AddSnippet/>
        </div>
        <div className="component-div snippet-list-div">
          <VisibleSnippetList/>
        </div>
      </div>
    </div>
  );
}

export default connect()(MainPage);
