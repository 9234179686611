import React from 'react'
import PropTypes from 'prop-types'
import SnippetItem from './SnippetItem'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


function SnippetList ({ snippets, onDeleteSnippet, onUpdateSnippet, onReorderSnippets }) {

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
  
    //console.log(result);
    onReorderSnippets(result.source.index, result.destination.index);
  }

    return (
      <div className="snippet-list">
        <DragDropContext onDragEnd={onDragEnd}>
            {
              <Droppable droppableId="snippets-droppable">
              {(provided) => (
                  <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  >
                  {snippets.map((snippet, index) => (
                      <Draggable key={"snippet-droppable--" + snippet.id} draggableId={"snippet-droppable--" + snippet.id} index={index}>
                      {(provided) => (
                          <div
                          className="snippet-div"
                          key={"snippet-div--" + snippet.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          
                          >
                          <SnippetItem id={snippet.id} text={snippet.text} deleteSnippetCallback = {onDeleteSnippet} updateSnippetCallback = {onUpdateSnippet} tabIndex={index} tabIndexListLength={snippets.length} tabIndexOffset={20} />
                          </div>
                      )}
                      </Draggable>
                  ))}
                  {provided.placeholder}
                  </div>
              )}
              </Droppable>
            }
       </DragDropContext>
       {
         (snippets.length <= 0) && 
          <div className="snippet-list-empty-tips">
          <div className="snippet-list-empty-tips-column">
            <span>1. Input your text at the above text box.</span>
          </div>
          <div className="snippet-list-empty-tips-column">
            <span>2. Click <FontAwesomeIcon icon="plus" className="icon"/> to add to the list.</span>
          </div>
          <div className="snippet-list-empty-tips-column">
            <span>3. Click <FontAwesomeIcon icon="copy" className="icon"/> to copy the text.</span>
          </div>
          <div className="snippet-list-empty-tips-column padding-top-50">
            <span>Items will be saved in the browser only.</span>
          </div>
          </div>
       }
      </div>
      )
 
  
}

export default SnippetList
